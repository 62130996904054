import { Button, Drawer, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import OpenFolderIcon from 'src/common/icons/openFolder'

import './styles.css'
import PackageCreate from 'src/views/PackageCreate'

import ModuleView from 'src/views/ModuleView'
import { useEffect } from 'react'
import trainingsService from 'src/services/trainings.service'
// import { useNavigate } from 'react-router-dom'
import TabPill from 'src/common/components/TabPill'
import dayjs from 'dayjs'
import ViewPackage from './components/viewPackage'
import { TrainingPackageStatsBlock } from './components/tpStatsBlock'
import { SearchInput } from 'src/common/components/DataGrid'
import useDebounce from 'src/hooks'
import Highlighter from 'react-highlight-words'
import statsService from 'src/services/stats.service'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import ArchiveDialog from 'src/common/components/ArchiveDialog'
import Spacer from 'src/common/components/Spacer'

const STATUSES = [
  { code: 'PUBLISHED', name: 'Published' },
  { code: 'DRAFT', name: 'Draft' },
  { code: 'ARCHIVED', name: 'Archived' }
]

const TrainingPackageListItem = ({
  item,
  onEditPackage = () => {},
  onView = () => {},
  handleUpdate = () => {},
  search
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleArchive = () => {
    trainingsService.archivePackage(item.id).then(() => {
      setOpenArchiveDialog(false)
      handleUpdate('archive')
    })
  }

  const handlePublish = () => {
    trainingsService.publishPackage(item.id).then(() => {
      handleUpdate('publish')
      setAnchorEl(null)
    })
  }
  return (
    <div key={item.id} className='training-package-item column'>
      <div className={`micro-training-item-rb  ${item.status !== 'ARCHIVED' && 'micro-training-item-rb-green'}`}></div>
      <div className='row'>
        <div className='column' style={{ width: '45%' }}>
          <p
            style={{
              fontWeight: '600',
              marginBottom: '6px',
              cursor: 'pointer',
              marginRight: 20
            }}
            className='package-description'
            onClick={() => onView(item.id)}
          >
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={item.name}
            />
          </p>
          <Tooltip title={item.description} arrow>
            <p
              style={{
                fontSize: '13px',
                color: '#76767F',
                marginRight: 20
              }}
              className='package-description'
            >
              <Highlighter
                highlightClassName='highlight-text'
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={item.description}
              />
            </p>
          </Tooltip>
        </div>
        <div className='row row-center' style={{ width: '8%' }}>
          <p className='sato24'>{item?.modulesCount || 0}</p>
        </div>
        <div className='row row-center' style={{ width: '12%', fontSize: '14px', opacity: 0.8 }}>
          <p>{item?.endDate ? dayjs(item?.endDate).format('MMM DD, YYYY') : ''}</p>{' '}
        </div>
        <div className='row row-center' style={{ width: '25%' }}>
          <p className='sato24'>{Math.round((item?.completedCount / item?.totalCount) * 100) || 0}%</p>
          <div
            style={{
              flex: 1
            }}
          >
            <div
              style={{
                height: '8px',
                width: '80%',
                backgroundColor: '#E7E7EE',
                position: 'relative',
                borderRadius: '8px',
                marginLeft: '8px'
              }}
            >
              <div
                style={{
                  height: '8px',
                  width: (Math.round((item?.completedCount / item?.totalCount) * 100) || 0) + '%',
                  backgroundColor: '#59D399',
                  position: 'relative',
                  borderRadius: '8px'
                }}
              ></div>
              <div
                style={{
                  height: '8px',
                  width: (Math.round((item?.failedCount / item?.totalCount) * 100) || 0) + '%',
                  backgroundColor: '#F0284C',
                  position: 'relative',
                  borderRadius: '8px'
                }}
              ></div>
            </div>
          </div>
        </div>
        <div style={{ width: '10%', justifyContent: 'flex-end' }} className='row row-center'>
          <p className='sato24' style={{ textAlign: 'right' }}>
            {item?.usersCount}
          </p>
          {/* <Spacer /> */}
          <IconButton onClick={handleOpenMenu}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 7.125C12.6213 7.125 13.125 6.62132 13.125 6C13.125 5.37868 12.6213 4.875 12 4.875C11.3787 4.875 10.875 5.37868 10.875 6C10.875 6.62132 11.3787 7.125 12 7.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 19.125C12.6213 19.125 13.125 18.6213 13.125 18C13.125 17.3787 12.6213 16.875 12 16.875C11.3787 16.875 10.875 17.3787 10.875 18C10.875 18.6213 11.3787 19.125 12 19.125Z'
                fill='#1B1B26'
              />
            </svg>
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#303051',
                color: '#ffffff',
                width: '200px',
                borderRadius: '12px'
              },
              '& li': {
                opacity: '0.7',
                fontFamily: 'Plus Jakarta Sans',
                paddingLeft: '24px'
              },
              '& li:hover': {
                opacity: '1'
              }
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                onView(item.id)
                setAnchorEl(null)
              }}
            >
              <svg
                style={{ marginRight: '10px' }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g opacity='0.76'>
                  <path
                    opacity='0.2'
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25ZM12 15.7508C11.2583 15.7508 10.5333 15.5308 9.91661 15.1188C9.29993 14.7067 8.81928 14.1211 8.53545 13.4358C8.25162 12.7506 8.17736 11.9966 8.32205 11.2692C8.46675 10.5418 8.8239 9.87357 9.34835 9.34913C9.8728 8.82468 10.541 8.46753 11.2684 8.32283C11.9958 8.17814 12.7498 8.2524 13.4351 8.53623C14.1203 8.82006 14.706 9.30071 15.118 9.91739C15.5301 10.5341 15.75 11.2591 15.75 12.0008C15.75 12.9953 15.3549 13.9492 14.6516 14.6524C13.9484 15.3557 12.9946 15.7508 12 15.7508Z'
                    fill='white'
                  />
                  <path
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
              </svg>
              View Package
            </MenuItem>
            {['DRAFT', 'PUBLISHED'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  // onEdit(item.id)
                  onEditPackage(item.id)
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g opacity='0.6'>
                    <path
                      opacity='0.2'
                      d='M12.75 6.00184L18 11.2518L20.4697 8.78217C20.6103 8.64152 20.6893 8.45075 20.6893 8.25184C20.6893 8.05293 20.6103 7.86216 20.4697 7.72151L16.2803 3.53217C16.1397 3.39152 15.9489 3.3125 15.75 3.3125C15.5511 3.3125 15.3603 3.39152 15.2197 3.53217L12.75 6.00184Z'
                      fill='white'
                    />
                    <path d='M12.75 6L18 11.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                    <path
                      d='M9 20.2518H4.5C4.30109 20.2518 4.11032 20.1728 3.96967 20.0322C3.82902 19.8915 3.75 19.7008 3.75 19.5018V15.3125C3.75 15.214 3.7694 15.1165 3.80709 15.0255C3.84478 14.9345 3.90003 14.8518 3.96967 14.7822L15.2197 3.53217C15.3603 3.39152 15.5511 3.3125 15.75 3.3125C15.9489 3.3125 16.1397 3.39152 16.2803 3.53217L20.4697 7.72151C20.6103 7.86216 20.6893 8.05293 20.6893 8.25184C20.6893 8.45075 20.6103 8.64152 20.4697 8.78217L9 20.2518Z'
                      stroke='white'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </svg>
                Edit
              </MenuItem>
            )}
            {['DRAFT'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  handlePublish()
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M7.5 15.75L18 5.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M18 14.625V5.25H8.625' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M3.75 20.25H20.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                Publish
              </MenuItem>
            )}
            {['DRAFT', 'PUBLISHED'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  setOpenArchiveDialog(true)
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.2'
                    d='M20.25 6.75V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V6.75H20.25Z'
                    fill='white'
                  />
                  <path
                    d='M19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V6.75L5.25 3.75H18.75L20.25 6.75V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M8.82031 14.0625L12.0023 17.2445L15.1842 14.0625'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path d='M12 9.75V17.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M3.75 6.75H20.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                Archive
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <ArchiveDialog
        open={openArchiveDialog}
        str={item?.name}
        description={'This package will be archived. This action can be reversed'}
        onAction={handleArchive}
        onClose={() => setOpenArchiveDialog(false)}
      />
    </div>
  )
}

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px',
          lineHeight: '16px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const PackagesGroupByStatus = ({ item, packages, onEdit, onView, handleUpdate, search }) => {
  const [sortedItems, setSortedItems] = useState(null)
  const [sortBy, setSortBy] = useState(null)
  const handleSort = field => {
    const fieldset = {
      name: 'name',
      'end date': 'endDate',
      modules: 'modulesCount',
      users: 'usersCount',
      'completed training': 'completedCount'
    }
    const fieldName = fieldset[field.toLowerCase()]

    setSortedItems(
      packages
        .map(el => ({
          ...el,
          modules: el.trainingModuleIds?.length || 0
        }))
        .sort((a, b) => {
          if (fieldName == 'completedCount') {
            if (
              (Math.round((a?.completedCount / a?.totalCount) * 100) || 0) <
              (Math.round((b?.completedCount / b?.totalCount) * 100) || 0)
            ) {
              return sortBy?.asc ? -1 : 1
            }
            if (
              (Math.round((a?.completedCount / a?.totalCount) * 100) || 0) >
              (Math.round((b?.completedCount / b?.totalCount) * 100) || 0)
            ) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          } else if (fieldName == 'endDate') {
            if (dayjs(a[fieldName] || '1990-01-01').unix() < dayjs(b[fieldName] || '1990-01-01').unix()) {
              return sortBy?.asc ? -1 : 1
            }
            if (dayjs(a[fieldName] || '1990-01-01').unix() > dayjs(b[fieldName] || '1990-01-01').unix()) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          } else if (fieldName == 'name') {
            if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) {
              return sortBy?.asc ? -1 : 1
            }
            if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          } else {
            if (a[fieldName] < b[fieldName]) {
              return sortBy?.asc ? -1 : 1
            }
            if (a[fieldName] > b[fieldName]) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          }
        })
    )
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }
  return (
    <div className='packages-group'>
      <h2
        style={{
          marginTop: '36px',
          marginBottom: '8px',
          fontFamily: 'Satoshi',
          fontSize: '24px'
        }}
      >
        {item.name}
      </h2>

      <div className=''>
        <div className='row row-center groups-list-heading'>
          <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'45%'} />
          <SortHeader name='Modules' sort={sortBy} onSort={handleSort} width={'8%'} />
          <SortHeader name='End date' sort={sortBy} onSort={handleSort} width={'12%'} />
          <SortHeader name='Completed training' sort={sortBy} onSort={handleSort} width={'25%'} />
          <SortHeader name='Users' sort={sortBy} onSort={handleSort} width={'10%'} />
        </div>
        {sortedItems
          ? packages &&
            packages.length &&
            sortedItems
              .filter(el => el.status == item.code)
              ?.map(item => (
                <TrainingPackageListItem
                  search={search}
                  key={item.id}
                  item={item}
                  onEditPackage={onEdit}
                  onView={onView}
                  handleUpdate={handleUpdate}
                />
              ))
          : packages &&
            packages.length &&
            packages
              .filter(el => el.status == item.code)
              ?.map(item => (
                <TrainingPackageListItem
                  search={search}
                  key={item.id}
                  item={item}
                  onEditPackage={onEdit}
                  onView={onView}
                  handleUpdate={handleUpdate}
                />
              ))}
      </div>
    </div>
  )
}

const TrainingPackages = () => {
  // const navigate = useNavigate()

  const [uncheckedTabs, setUncheckedtabs] = useState([])
  const [openShowPackageDrawer, setOpenShowPackageDrawer] = useState(false) ///
  const [openViewPackageDrawer, setOpenViewPackageDrawer] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [openShowModuleDrawer, setOpenShowModuleDrawer] = useState(false)

  const [packages, setPackages] = useState(null)
  const [modules, setModules] = useState(null)
  const [selectedId, setSelectedId] = useState(null)

  const [stats, setStats] = useState(null)
  const [totalUserCount, setTotalUserCount] = useState(null)

  const [filtredPackages, setFiltredPackages] = useState(null)

  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 500)

  const [loading, setLoading] = useState(true)

  const onFilter = criteria => {
    trainingsService.getPackages(criteria.searchText).then(resp => {
      setPackages(resp.data)
      setFiltredPackages(resp.data)
    })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      onFilter({ searchText: search })
    } else {
      onFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  const [successMessage, setSuccessMessage] = useState(null)

  const handleSelectItem = id => {
    setSelectedId(id)
    setOpenViewPackageDrawer(false)
    setOpenShowPackageDrawer(true)
    setIsEdit(true)
  }

  const handleViewPackage = id => {
    setSelectedId(id)
    setOpenViewPackageDrawer(true)
  }

  const handleUpdate = eventType => {
    trainingsService.getPackages().then(resp => {
      setPackages(resp.data)
      setFiltredPackages(resp.data)
      if (eventType == 'publish') {
        setSuccessMessage('Successfully published package')
      }
      if (eventType == 'archive') {
        setSuccessMessage('Successfully archived package')
      }
    })
  }

  const handleArchive = id => {
    trainingsService.archivePackage(id).then(() => {
      handleUpdate('archive')
    })
  }

  const handleBack = () => {
    setOpenShowModuleDrawer(false)
    setOpenShowPackageDrawer(true)
  }
  const handleOpenModule = moduleItems => {
    setModules(moduleItems)
    setOpenShowModuleDrawer(true)
    setOpenShowPackageDrawer(false)
  }

  useEffect(() => {
    Promise.all([
      statsService.getCurrentCompanyStats(),
      statsService.getCurrentCompanySecurityTrainingStats(),
      trainingsService.getPackages()
    ])
      .then(values => {
        const [currentCompanyStatsResp, currentCompanySecurityTrainingStatsResp, packagesResp] = values
        setTotalUserCount(currentCompanyStatsResp.data)
        setStats(currentCompanySecurityTrainingStatsResp.data)

        setPackages(packagesResp.data)
        setFiltredPackages(packagesResp.data)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }, [])

  const tabs = [
    {
      id: 2,
      text: 'Published',
      itemsCount: packages?.filter(el => el.status == 'PUBLISHED').length || 0
    },
    {
      id: 4,
      text: 'Draft',
      itemsCount: packages?.filter(el => el.status == 'DRAFT').length || 0
    },
    {
      id: 3,
      text: 'Archived',
      itemsCount: packages?.filter(el => el.status == 'ARCHIVED').length || 0
    }
  ]

  const handleTabPillSelect = name => {
    setUncheckedtabs(uncheckedTabs.includes(name) ? uncheckedTabs.filter(el => el !== name) : [...uncheckedTabs, name])
  }

  return (
    <div className='column' style={{ padding: '24px', height: '100vh' }}>
      <div style={{ marginBottom: '32px' }} className='row row-center'>
        <h1 style={{ marginBottom: '0', marginRight: 20, fontSize: 24 }}>Training Packages</h1>
        <Spacer />
        <SearchInput
          sx={{
            flex: 1,
            width: '400px',
            margin: '0',
            marginRight: '20px'
          }}
          onChange={e => setSearch(e.target.value)}
          value={search}
          size='small'
          placeholder='Search'
        />
        {/* <Spacer /> */}
        <Button
          variant={'contained'}
          sx={{
            background: '#6123FF',
            borderRadius: '12px',
            fontFamily: 'Plus Jakarta Sans',
            color: '#fff',
            textTransform: 'none',
            width: 270
          }}
          startIcon={<OpenFolderIcon />}
          onClick={() => {
            setOpenShowPackageDrawer(true)
          }}
        >
          Create New Package
        </Button>
      </div>

      {loading ? (
        <div className='column column-center' style={{ flex: 1 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className='row row-center' style={{ marginTop: '24px', marginBottom: '36px' }}>
            {tabs.map(el => (
              <TabPill
                key={el.id}
                checked={!uncheckedTabs.includes(el.text)}
                onSelect={handleTabPillSelect}
                text={el.text}
                amount={el.itemsCount}
              />
            ))}
          </div>

          <div
            className='training-packages-content'
            style={{
              background: '#F8F8FB',
              borderTop: '1px solid #E7E7EE',
              padding: '24px 32px',
              margin: '0px -23px -55px -32px',
              height: 'calc(100vh - 212px)',
              overflow: 'auto'
            }}
          >
            {packages?.filter(pack => !uncheckedTabs.includes(STATUSES.find(item => item.code == pack.status).name))
              .length == 0 && (
              <div
                style={{
                  alignItems: 'center',
                  marginTop: '100px'
                }}
                className='column'
              >
                <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M30 37.5V26.25C30 25.2554 30.3951 24.3016 31.0983 23.5984C31.8016 22.8951 32.7554 22.5 33.75 22.5H101.25C102.245 22.5 103.198 22.8951 103.902 23.5984C104.605 24.3016 105 25.2554 105 26.25V78.75C105 79.7445 104.605 80.6984 103.902 81.4016C103.198 82.1049 102.245 82.5 101.25 82.5H90'
                    fill='#E7E7EE'
                  />
                  <rect x='17' y='38' width='73' height='47' fill='white' />
                  <path
                    d='M86.25 37.5H18.75C16.6789 37.5 15 39.1789 15 41.25V93.75C15 95.8211 16.6789 97.5 18.75 97.5H86.25C88.3211 97.5 90 95.8211 90 93.75V41.25C90 39.1789 88.3211 37.5 86.25 37.5Z'
                    stroke='#E7E7EE'
                    strokeWidth='5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path d='M15 52.5H90' stroke='#E7E7EE' strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' />
                </svg>

                <p
                  style={{
                    color: '#76767F',
                    textAlign: 'center'
                  }}
                >
                  Here you will find the trainings specifically <br /> created for you
                </p>
              </div>
            )}
            {packages?.filter(pack => !uncheckedTabs.includes(STATUSES.find(item => item.code == pack.status).name))
              .length > 0 && <TrainingPackageStatsBlock stats={stats} totalUserCount={totalUserCount} />}

            {STATUSES.map(
              el =>
                !uncheckedTabs.includes(el.name) &&
                packages?.filter(pack => pack.status == el.code).length > 0 && (
                  <PackagesGroupByStatus
                    key={el.code}
                    item={el}
                    packages={packages}
                    onEdit={handleSelectItem}
                    onView={handleViewPackage}
                    handleUpdate={handleUpdate}
                    search={search}
                  />
                )
            )}
          </div>
        </>
      )}

      <Drawer
        sx={{ '& .MuiDrawer-paper': { background: '#F8F8FB' } }}
        anchor='right'
        open={openShowPackageDrawer}
        onClose={() => {
          setOpenShowPackageDrawer(false)
          setIsEdit(false)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1052px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenShowPackageDrawer(false)
            setIsEdit(false)
          }}
        >
          <CloseDrawer />
        </div>
        <PackageCreate
          onOpenModule={handleOpenModule}
          handleClose={event => {
            setOpenShowPackageDrawer(false)
            setIsEdit(false)
            handleUpdate()
            if (event) {
              setSuccessMessage('Training Package ' + event)
            }
          }}
          selectedId={selectedId}
          isEdit={isEdit}
        />
      </Drawer>
      <Drawer
        sx={{ '& .MuiPaper-elevation': { backgroundColor: '#F8F8FB' } }}
        anchor='right'
        open={openShowModuleDrawer}
        onClose={() => setOpenShowModuleDrawer(false)}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '982px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenShowModuleDrawer(false)}
        >
          <CloseDrawer />
        </div>
        <ModuleView modules={modules} onBack={handleBack} />
      </Drawer>
      <Drawer anchor='right' open={openViewPackageDrawer} onClose={() => setOpenViewPackageDrawer(false)}>
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '982px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenViewPackageDrawer(false)}
        >
          <CloseDrawer />
        </div>
        <ViewPackage
          onArchive={handleArchive}
          onEdit={handleSelectItem}
          id={selectedId}
          handleUpdate={handleUpdate}
          onClose={() => setOpenViewPackageDrawer(false)}
          totalUserCount={totalUserCount}
        />
      </Drawer>
      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
    </div>
  )
}

export default TrainingPackages
