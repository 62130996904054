import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
// import Spacer from "src/common/components/Spacer";
import { useQuery } from 'src/hooks'
import { LoginInput } from 'src/pages/auth-link'
import authService from 'src/services/auth.service'
import companyService from 'src/services/company.service'
import settingsService from 'src/services/settings.service'

const ConnectorIcon = ({ type }) => {
  if (type == 'GOOGLE') {
    return (
      <svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M29.403 15.3598C29.403 14.3467 29.305 13.301 29.1416 12.3206H14.9915V18.1048H23.096C22.7692 19.9675 21.6908 21.6015 20.0895 22.6472L24.926 26.4053C27.7691 23.7583 29.403 19.9022 29.403 15.3598Z'
          fill='#4280EF'
        />
        <path
          d='M14.9915 30C19.0437 30 22.4424 28.6602 24.926 26.3726L20.0895 22.6472C18.7496 23.5622 17.0176 24.0851 14.9915 24.0851C11.07 24.0851 7.76946 21.4381 6.56033 17.9088L1.59311 21.7322C4.14208 26.7975 9.30538 30 14.9915 30Z'
          fill='#34A353'
        />
        <path
          d='M6.56033 17.876C5.93942 16.0133 5.93942 13.9872 6.56033 12.1245L1.59311 8.26837C-0.531035 12.5167 -0.531035 17.5166 1.59311 21.7322L6.56033 17.876Z'
          fill='#F6B704'
        />
        <path
          d='M14.9915 5.94817C17.1157 5.91549 19.2071 6.73247 20.7431 8.20303L25.024 3.88938C22.3117 1.34041 18.717 -0.0321088 14.9915 0.000570345C9.30538 0.000570345 4.14208 3.20312 1.59311 8.26839L6.56033 12.1245C7.76946 8.5625 11.07 5.94817 14.9915 5.94817Z'
          fill='#E54335'
        />
      </svg>
    )
  }
  if (type == 'MICROSOFT') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='22' height='22'>
        <path d='M0 0h15.206v15.206H0z' fill='#f25022' />
        <path d='M16.794 0H32v15.206H16.794z' fill='#7fba00' />
        <path d='M0 16.794h15.206V32H0z' fill='#00a4ef' />
        <path d='M16.794 16.794H32V32H16.794z' fill='#ffb900' />
      </svg>
    )
  }
}

export const Alerts = () => {
  const { setErrorMessage } = useOutletContext()

  const [form, setForm] = useState({
    microsoftId: '',
    mainContact: '',
    googleId: ''
  })

  const [googleCustomerIdChanged, setGoogleCustomerIdChanged] = useState(false)
  const [googleConnected, setGoogleConnected] = useState(false)
  const [googleError, setGoogleError] = useState('')

  const [outlookCustomerIdChanged, setOutlookCustomerIdChanged] = useState(false)
  const [outlookConnected, setOutlookConnected] = useState(false)

  const query = useQuery()

  const outlookSyncDone = query.get('syncDone') == '1'

  useEffect(() => {
    companyService.getCompanyConfigs().then(resp => {
      if (outlookSyncDone && localStorage.getItem('microsoftId')) {
        companyService
          .updateCompanyConfigs({
            ...resp.data,
            microsoftId: localStorage.getItem('microsoftId')
          })
          .then(() => {
            setGoogleConnected(resp.data.googleId)
            setOutlookConnected(resp.data.microsoftId)
            setForm({
              microsoftId: localStorage.getItem('microsoftId'),
              mainContact: resp.data.mainContact,
              googleId: resp.data.googleId
            })
            localStorage.removeItem('microsoftId')
            setOutlookConnected(true)
          })
          .catch(err => {
            console.error(err)
            setErrorMessage('Something went wrong')
          })
      } else {
        setGoogleConnected(resp.data.googleId)
        setOutlookConnected(resp.data.microsoftId)
        setForm({
          microsoftId: resp.data.microsoftId,
          mainContact: resp.data.mainContact,
          googleId: resp.data.googleId
        })
      }
    })
  }, [])

  const handleSaveGoogle = () => {
    companyService.getCompanyConfigs().then(resp => {
      authService
        .healthGoogleAlerts(form.googleId, form.mainContact)
        .then(healthResp => {
          if (healthResp.data === 'Google workflows successfully set up') {
            companyService
              .updateCompanyConfigs({
                ...resp.data,
                mainContact: form.mainContact,
                googleId: form.googleId
              })
              .then(() => {
                setGoogleConnected(true)
                setGoogleCustomerIdChanged(false)
              })
              .catch(err => {
                console.error(err)
              })
          } else if (healthResp.data === 'FAIL') {
            setGoogleError('Please check your google data.')
          } else if (healthResp.data === 'Google workflows setup failed') {
            setGoogleError('Google workflows setup failed.')
          }
        })
        .catch(() => {
          setGoogleError('Please check your google data.')
        })
    })
  }

  const handleSaveMicrosoft = () => {
    localStorage.setItem('microsoftId', form.microsoftId)
    settingsService.getOutlookWorkflowsAuthUrl().then(resp => {
      window.location = resp.data
    })
  }

  return (
    <div className='settings-section'>
      <div className='column'>
        <h2 id='integrations-section'>Workflows</h2>

        <p style={{ maxWidth: '730px', lineHeight: '24px' }}>Security monitoring</p>

        <LoginInput
          sx={{ borderRadius: '20px', mb: '10px', mt: '20px', width: 400, }}
          label='Cyberlift application ID'
          variant='standard'
          value={'100489908663158603949'}
          onChange={() => { }}
          disabled
        />
      </div>

      <h2 id='integrations-section' style={{ marginTop: 40, marginBottom: 20 }}>
        Alert providers
      </h2>

      <div className='row row-center' style={{ marginBottom: 20 }}>
        <ConnectorIcon type={'GOOGLE'} />
        <p style={{ fontWeight: 600, marginLeft: 10 }}>Google</p>

        {googleConnected && (
          <div style={{ color: '#00BC62', marginLeft: 20 }} className='row row-center'>
            <p>Connected</p>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M13.5 4.5L6.5 11.4997L3 8'
                stroke='#00BC62'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        )}

        {googleError && (
          <div style={{ color: '#B40020', marginLeft: 20 }} className='row row-center'>
            <p>{googleError}</p>
          </div>
        )}
      </div>
      <LoginInput
        error={googleError}
        sx={{
          borderRadius: '20px',
          mb: '10px',
          mt: '10px',
          width: 400,
          borderColor: googleError && '#B40020'
        }}
        label='Main contact'
        variant='standard'
        value={form.mainContact || ''}
        onChange={e => {
          setForm({ ...form, mainContact: e.target.value })
          setGoogleCustomerIdChanged(true)
          setGoogleConnected(false)
        }}
      />
      <div className='row row-center' style={{ marginBottom: '20px' }}>
        <LoginInput
          error={googleError}
          sx={{
            borderRadius: '20px',
            mr: '20px',
            width: 400,
            borderColor: googleError && '#B40020'
          }}
          label='Customer ID'
          variant='standard'
          value={form.googleId || ''}
          onChange={e => {
            setForm({ ...form, googleId: e.target.value })
            setGoogleCustomerIdChanged(true)
            setGoogleConnected(false)
          }}
        />

        {googleCustomerIdChanged && (
          <Button
            sx={{
              color: '#6123FF',
              textTransform: 'none'
            }}
            onClick={handleSaveGoogle}
          >
            Authorize
          </Button>
        )}
      </div>

      <div className='row row-center' style={{ marginBottom: 20, marginTop: 30 }}>
        <ConnectorIcon type={'MICROSOFT'} />
        <p style={{ fontWeight: 600, marginLeft: 10 }}>Microsoft</p>

        {outlookConnected && (
          <div style={{ color: '#00BC62', marginLeft: 20 }} className='row row-center'>
            <p>Connected</p>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M13.5 4.5L6.5 11.4997L3 8'
                stroke='#00BC62'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        )}
      </div>
      <div className='row row-center' style={{ marginBottom: '20px' }}>
        <LoginInput
          sx={{ borderRadius: '20px', mr: '20px', width: 400 }}
          label='Tennant ID'
          variant='standard'
          value={form.microsoftId || ''}
          onChange={e => {
            setForm({ ...form, microsoftId: e.target.value })
            // setShowSaveButton(true)
            setOutlookConnected(false)
            setOutlookCustomerIdChanged(true)
          }}
        />
        {outlookCustomerIdChanged && (
          <Button
            sx={{
              color: '#6123FF',
              textTransform: 'none'
            }}
            onClick={handleSaveMicrosoft}
          >
            Authorize
          </Button>
        )}
      </div>
    </div>
  )
}
